import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, FormControlLabel, Checkbox, FormGroup
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import InlineMedia from "../../../components/inline-media/InlineMedia";
import TabPanel from "../../components/TabPanel";
import ApprovalMultiEditTranslate from "./fieldGroups/translate/ApprovalMultiEditTranslate";
import GeneralInfoApproval from "./fieldGroups/GeneralInfoApproval";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    useInlineMediaUploadApprovalMutation,
    InlineMediaUploadApprovalMutation,
    useApprovalMultiEditQuery,
    ApprovalMultiEditQuery, useMultiUpdateApprovalMutation
} from "../../../graphql/settings/approval/approval.graphql-gen";

// Types
import {MediaItemType} from "../../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {shallowEqual} from "react-redux";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    //title: Yup.string().required("Required"),
});

const ApprovalMultiEdit: React.FC = () => {
    let title: string = 'Benefit Multi Edit'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['image'];
    const navigate = useNavigate();
    const model: string = 'Approval';
    const location: any = useLocation();

    // Queries

    const {data: data, error: error, loading: loading} = useApprovalMultiEditQuery({
        variables: {
            ids: location.state.ids,
            model,
            fields: inlineModels,
        },
        onCompleted: (data: ApprovalMultiEditQuery) => {
            setState({});
            setInicialValue({});
            //setUserId(data.user.user?.id);
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'image':
                            setImage(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [multiUpdateApproval] = useMultiUpdateApprovalMutation();
    const [inlineMediaUpload] = useInlineMediaUploadApprovalMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [image, setImage] = useState<any>(dataObj.approval ? dataObj.approval.image : {});
    const [imageItems, setImageItems] = useState<any>(dataObj.approval ? dataObj.approval.image.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();

    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveApproval = () => {

        updateApprovalMultiEditMutation(state);
        setInicialValue({
            "data": state, "images": imageItems
        });
    }

    // Update approval mutation
    const updateApprovalMultiEditMutation = (data: any) => {
        multiUpdateApproval({
            variables: {
                model,
                ids: location.state.ids,
                data,
                image: imageItems,
                removedMedia
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        navigate(`/settings/benefits`, {replace: true})
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "images": imageItems});
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }


    const onAddImageHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'image',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadApprovalMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setImageItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onImageUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = imageItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        imageItems[foundIndex] = updateData;
        setImageItems(imageItems)
    }

    // Remove media
    const onImageRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setImageItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {
            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }


        // Update select value
        setState({...state, [fieldName]: value});
    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Approval Multi Edit"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                    <Typography variant="h4" gutterBottom >
                        {dataObj.approvalMultiEdit.map((item: any, index: number) => {
                            if(item.approval ){
                                return item.approval.title
                            }
                        }).join(', ')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveApproval}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                    <Tab label="Translate Germany" {...tabsProps(2)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoApproval
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>


                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Image
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={imageItems}
                                                        fieldType={image.fieldType}
                                                        allowedExtensions={image.allowedExtensions}
                                                        isMultiple={image.isMultiple}
                                                        onAdd={onAddImageHandler}
                                                        onUpdate={onImageUpdateHandler}
                                                        onDelete={onImageRemoveHandler}/>
                                                    <FormGroup className="clear-check-top">
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                name="clearImage"
                                                                checked={state.clearImage ? state.clearImage : false}
                                                                onChange={(e) =>{inputHandler(e)}}
                                                            />
                                                        } label="Clear"/>
                                                    </FormGroup>
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <ApprovalMultiEditTranslate
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default ApprovalMultiEdit;
