import React, {ChangeEvent, useEffect, useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Divider as MuiDivider,
    Grid, InputLabel, Stack, TextField as MuiTextField,
    Typography
} from "@mui/material";
import ReactQuill from "react-quill";


// Types
import {ContentElementItemType} from "../../types/inlineContentElementType";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import Loader from "../Loader";

const Divider = styled(MuiDivider)(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ContentElementItemTranslate: React.FC<{ item: ContentElementItemType, onUpdate: (data: any) => void }> = (props) => {

    const [item, setItem] = useState(props.item);
    const [loader, setLoader] = useState<boolean>(false);


    useEffect(() => {
        props.onUpdate(item);
    }, [item]);

    // Set loading
    if (loader) {
        return <Loader/>
    }


    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };


    // Update description on change
    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setItem({...item, ['descriptionDe']: event});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };






    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={9} md={11}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                {item.titleDe
                                    ? <Typography>{item.titleDe} ({item.title})</Typography>
                                    : <Typography>{item.title}</Typography>
                                }

                            </Stack>

                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="titleDe"
                                        label="Title De"
                                        value={item.titleDe}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="title"
                                        label="Title"
                                        value={item.title}
                                        fullWidth
                                        variant="outlined"
                                        my={2}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <QuillWrapper className="full-width">
                                        <InputLabel className="mb-5">Description De</InputLabel>
                                        <ReactQuill
                                            theme="snow"
                                            value={item.descriptionDe}
                                            onChange={(e) => {
                                                handleChangeDescriptionDe(e);
                                            }}
                                        />
                                    </QuillWrapper>
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <QuillWrapper className="full-width read-only">
                                        <InputLabel className="mb-5">Description</InputLabel>
                                        <ReactQuill
                                            theme="snow"
                                            value={item.description}
                                            readOnly={true}
                                        />
                                    </QuillWrapper>

                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="linkDe"
                                        label="Link De"
                                        value={item.linkDe}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="link"
                                        label="Link"
                                        value={item.link}
                                        fullWidth
                                        variant="outlined"
                                        my={2}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="labelDe"
                                        label="Link label De"
                                        value={item.labelDe}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="label"
                                        label="Link label"
                                        value={item.label}
                                        fullWidth
                                        variant="outlined"
                                        my={2}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="videoDe"
                                        label="Video De"
                                        value={item.videoDe}
                                        fullWidth
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} mb={5}>
                                    <TextField
                                        name="video"
                                        label="Video"
                                        value={item.video}
                                        fullWidth
                                        variant="outlined"
                                        my={2}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>


            </Grid>
        </React.Fragment>
    );
}

export default ContentElementItemTranslate;
