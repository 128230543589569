import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox,
    Divider as MuiDivider
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoUser: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange} = props;

    const [valueGender, setValueGender] = useState<string>(values.gender ? values.gender : '1');
    const [valueHidden, setValueHidden] = useState<boolean>(values.hidden ? values.hidden : false);


    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValueGender((event.target as HTMLInputElement).value);
    };

    //update checkbox
    const handleHiddenChange = (event:any) => {
        setValueHidden(event.target.checked);
    };


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General information
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="hidden"
                                checked={valueHidden}
                                onChange={(e) =>{
                                    handleHiddenChange(e);
                                    onChange(e)}
                            }
                            />
                        } label="Disabled User"/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Personal information
            </Typography>
            <Grid container spacing={6}>

                <Grid item xs={12} md={4} mb={5}>
                    <FormControl>
                        <FormLabel
                            id="gender">Gender</FormLabel>
                        <RadioGroup
                            aria-labelledby="gender"
                            name="gender"
                            value={valueGender}
                            onChange={(e) => {
                                handleRadioChange(e);
                                onChange(e)
                            }}
                        >
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Female"/>
                            <FormControlLabel value="2" control={<Radio/>}
                                              label="Male"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="firstName"
                        label="First Name *"
                        value={values.firstName ? values.firstName : ''}
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="lastName"
                        label="Last name *"
                        value={values.lastName ? values.lastName : ''}
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        type="email"
                        name="email"
                        label="Email *"
                        value={values.email ? values.email : ''}
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoUser;
