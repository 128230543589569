import React, {ChangeEvent, useEffect, useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, Divider as MuiDivider, FormControl,
    Grid,
    IconButton, InputLabel, MenuItem, Select,
    Stack,
    TextField as MuiTextField,
    Typography
} from "@mui/material";

// Types
import {TechnicalSpecificationItemType} from "../../../types/inlineTechnicalSpecificationsType";

// Icons
import {Plus, Trash2 as TrashIcon} from "react-feather";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const TechnicalSpecificationItem: React.FC<{ item: TechnicalSpecificationItemType, types: any, onUpdate: (data: any) => void, onDelete: (data: any) => void, multiEdit?: boolean }> = (props) => {
    const [item, setItem] = useState(props.item);
    const types = props.types;

    //------------------------------- Public methods -------------------------------------

    useEffect(() => {
        props.onUpdate(item);
    }, [item]);

    // This function is called when the select changes
    const inputHandler = (event: any) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };

    // Trigger delete
    const deleteHandler = () => {
        props.onDelete(item);
    };

    // Update options title
    const onUpdateOptionHandler = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        const value = event.target.value;
        const foundIndex = item.options.findIndex((option: { id: string, title: string }) => option.id == id);
        item.options[foundIndex].title = value;
        setItem({...item});
    }
    const onUpdateOptionHandlerDe = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        const value = event.target.value;
        const foundIndex = item.options.findIndex((option: { id: string, titleDe: string }) => option.id == id);
        item.options[foundIndex].titleDe = value;
        setItem({...item});
    }

    // Trigger option delete
    const deleteOptionHandler = (id: string) => {
        const updateItem: any = item.options.filter((option: { id: string, title: string }) => option.id !== id);
        setItem({...item, ['options']: updateItem});
    };

    // Prepare files for upload
    const onAddOptionNew = () => {
        const newOption = {
            id: new Date().getTime().toString(),
            title: '',
        }
        const updateItem = item;
        updateItem.options = [...item.options, newOption];

        setItem({...updateItem});
    }


    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={9} md={10}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >
                            <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                {item.type
                                    ? <React.Fragment>
                                        <Typography>{types.find((type: any) => type.id === item.type).title}</Typography>
                                        <Typography>(order: {item.sorting})</Typography>
                                    </React.Fragment>

                                    : <Typography>New specification</Typography>
                                }

                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6} mb={5} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <FormControl className="full-width">
                                        <InputLabel>Type</InputLabel>
                                        <Select
                                            name="type"
                                            value={item.type}
                                            label="Type"
                                            onChange={(e: any) => {
                                                inputHandler(e);
                                            }}
                                        >
                                            {types.map((type: { id: string, title: string }) => (
                                                <MenuItem key={type.id} value={type.id}>
                                                    {type.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        name="sorting"
                                        label="Order"
                                        value={item.sorting}
                                        fullWidth
                                        onChange={(e: any) => inputHandler(e)}
                                        onBlur={() => updateHandler()}
                                        variant="outlined"
                                        my={2}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Divider mb={10}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mb={5}>
                                <Typography variant="h6" mb={5}>
                                    Options
                                </Typography>
                            </Grid>

                            {item.options &&
                                item.options.map((option: { id: string, title: string, titleDe?: string }) => {
                                    return (
                                        <Grid container spacing={6} alignItems="center" key={option.id}>
                                            <Grid item xs={10} md={5} mb={5}>
                                                <TextField
                                                    name="title"
                                                    label="Value"
                                                    value={option.title}
                                                    fullWidth
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateOptionHandler(e, option.id)}
                                                    onBlur={() => updateHandler()}
                                                    variant="outlined"
                                                    my={2}
                                                    required
                                                />
                                            </Grid>
                                            {props.multiEdit &&
                                                <Grid item xs={10} md={5} mb={5}>
                                                    <TextField
                                                        name="titleDe"
                                                        label="Value De"
                                                        value={option.titleDe}
                                                        fullWidth
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateOptionHandlerDe(e, option.id)}
                                                        onBlur={() => updateHandler()}
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>
                                            }

                                            <Grid item mb={5}>
                                                <IconButton aria-label="delete" size="large"
                                                            onClick={() => deleteOptionHandler(option.id)}>
                                                    <TrashIcon/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }

                            <Grid item xs={12} mb={5}>
                                <Button variant="contained" component="span" startIcon={<Plus/>}
                                        onClick={() => onAddOptionNew()}>Add option</Button>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={3} md={2}>
                    <IconButton aria-label="delete" size="large"
                                onClick={() => deleteHandler()}>
                        <TrashIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default TechnicalSpecificationItem;
