import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const VideoProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
               Video
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="video"
                        label="Video"
                        value={values.video ? values.video : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVideo"
                                    checked={state.clearVideo ? state.clearVideo : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default VideoProduct;
