import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoProduct from "./fieldGroups/GeneralInfoProduct";
import AdditionalProduct from "./fieldGroups/AdditionalProduct";
import PromotionProduct from "./fieldGroups/PromotionProduct";
import CompanyProduct from "./fieldGroups/CompanyProduct";
import VideoProduct from "./fieldGroups/VideoProduct";
import InlineContentElement from "../../components/inline-content-element/InlineContentElement";
import Tree from "../../components/tree/Tree";
import InlineTechnicalSpecifications from "./inline-technical-specifications/InlineTechnicalSpecifications";
import TechnicalSpecificationProduct from "./fieldGroups/TechnicalSpecificationProduct";
import FiltersProduct from "./fieldGroups/FiltersProduct";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    NewProductQuery,
    ProductQuery,
    useCreateProductMutation,
    useNewProductQuery,
    useProductQuery,
    useUpdateProductMutation,
    CreateProductMutation,
    useInlineMediaUploadProductMutation,
    InlineMediaUploadProductMutation,
    useResetProductMutation
} from "../../graphql/product/product.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";
import {TechnicalSpecificationItemType} from "../../types/inlineTechnicalSpecificationsType";
import {ContentElementItemType} from "../../types/inlineContentElementType";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import RelatedProduct from "./fieldGroups/RelatedProduct";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    sku: Yup.string().required("Required"),
});

const Product: React.FC = () => {
    let title: string = 'New product';
    const model: string = 'Product';
    let dataObj: any = {};
    const params = useParams();
    let id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'headerImages', 'files'];
    const navigate = useNavigate();
    const multipleSelect: boolean = true;
    let fromMediaCenter: boolean = false;
    const location: any = useLocation();

    // Queries
    const {data: data, error: error, loading: loading} = useProductQuery({
        variables: {id, model, technicalSpecificationModel: 'TechnicalSpecification'},
        skip: id === '',
        onCompleted: (data: ProductQuery) => {
            setImages(data.product.images);
            setMediaItems(data.product.images?.items);

            setHeaderImages(data.product.headerImages);
            setHeaderImagesItems(data.product.headerImages?.items);

            setFiles(data.product.files);
            setFilesItems(data.product.files?.items);

            setContentElements(data.product.contentElements);

            setTechnicalSpecifications(data.product.technicalSpecifications);

            setUserId(data.user.user?.id);

            if (data.product && data.product.product) {
                setState(data.product.product);
                setInicialValue({
                    "data": data.product.product,
                    "images": data.product.images?.items,
                    "headerImages": data.product.headerImages?.items,
                    "files": data.product.files?.items,
                    "contentElements": data.product.contentElements,
                    "technicalSpecifications": data.product.technicalSpecifications,
                    "checked": data.categoriesThree.checked
                });
            }

            setChecked(data.categoriesThree.checked);
            setExpanded(data.categoriesThree.expanded);
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewProductQuery({
        variables: {
            fields: inlineModels,
            model,
            technicalSpecificationModel: 'TechnicalSpecification'
        },
        skip: id !== '',
        onCompleted: (data: NewProductQuery) => {
            setState({});
            setInicialValue({});

            setUserId(data.user.user?.id);

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'headerImages':
                            setHeaderImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateProduct] = useUpdateProductMutation();
    const [createProduct] = useCreateProductMutation();
    const [inlineMediaUpload] = useInlineMediaUploadProductMutation();
    const [resetProduct] = useResetProductMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.product ? dataObj.product.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.product ? dataObj.product.images.items : []);

    const [headerImages, setHeaderImages] = useState<any>(dataObj.product ? dataObj.product.headerImages : {});
    const [headerImagesItems, setHeaderImagesItems] = useState<any>(dataObj.product ? dataObj.product.headerImages.items : []);

    const [files, setFiles] = useState<any>(dataObj.product ? dataObj.product.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.product ? dataObj.product.files.items : []);

    const [contentElements, setContentElements] = useState<any>(dataObj.product ? dataObj.product.contentElements : []);
    const [technicalSpecifications, setTechnicalSpecifications] = useState<any>(dataObj.product ? dataObj.product.technicalSpecifications : []);

    const [userId, setUserId] = useState<any>();

    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(location.state?.submodelName ? 4 : (location.state?.fromMediaCenter ? 3 : 0));

    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [expanded, setExpanded] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.expanded : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.product && dataObj.product.product) {
        title = dataObj.product.product.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveProduct = (fieldName?: any, fieldType?: any, isMultiple?: any, submodelId?: any, submodelName?: string) => {
        if (id != '') {
            updateProductMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        } else {
            createProductMutation(state, fieldName, fieldType, isMultiple, submodelId, submodelName);
        }
        setInicialValue({
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "files": filesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });
    }

    // Update product mutation
    const updateProductMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?:boolean, submodelId?: any, submodelName?: string) => {
        updateProduct({
            variables: {
                model,
                id,
                data,
                categories: checked,
                images: mediaItems,
                files: filesItems,
                removedMedia,
                contentElements,
                technicalSpecifications,
                temp: fromMediaCenter
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
            if (fromMediaCenter) {
                navigate(`/mediacenter/`, {
                    replace: true,
                    state: {fieldName, fieldType, isMultiple, modelName: model, modelId: id, submodelId, submodelName}
                })
            }

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create product mutation
    const createProductMutation = (data: any, fieldName?: string, fieldType?: string, isMultiple?:boolean, submodelId?: any, submodelName?: string) => {
        createProduct({
            variables: {
                model,
                data,
                user: userId,
                categories: checked,
                images: mediaItems,
                files: filesItems,
                removedMedia,
                contentElements,
                technicalSpecifications,
                temp: fromMediaCenter
            },
        }).then((res: FetchResult<CreateProductMutation>) => {
            if (res.data && res.data.createProduct.product) {
                setUpdated(true);
                if(fromMediaCenter){
                    navigate(`/mediacenter/`, {
                        replace: true,
                        state: {fieldName, fieldType, isMultiple, modelName: model, modelId: res.data.createProduct.product.id, submodelId, submodelName}
                    })
                }else{
                    navigate(`/products/${res.data.createProduct.product.id}`, {replace: true})
                }
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        resetProduct({
            variables: {
                model,
                id
            }
        }).then(r => {
            if(!isLang){
                navigate(`/products`, {replace: true})
            }else{
                navigate(`/products/${lang}/${id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }
    const openDialog = () => {

        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "files": filesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }

    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "headerImages": headerImagesItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/products/${lang}/${id}`, {replace: true})
        }
    }

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };


    //------------------------ Inline media methods -------------------------------------

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onMediaBrowseHandler =() => {
        fromMediaCenter = true;
        onSaveProduct('images', images.fieldType, images.isMultiple);
    }


    // Add header images
    const onAddHeaderImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'headerImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setHeaderImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update header images
    const onHeaderImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = headerImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        headerImagesItems[foundIndex] = updateData;
        setHeaderImagesItems(headerImagesItems);
    }

    // Remove header images
    const onHeaderImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setHeaderImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onHeaderImagesBrowseHandler =() => {
        fromMediaCenter = true;
        onSaveProduct('headerImages', headerImages.fieldType, headerImages.isMultiple);
    }

    // Add files
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update files
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        filesItems[foundIndex] = updateData;
        setFilesItems(filesItems);
    }

    // Remove files
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    const onFilesBrowseHandler =() => {
        fromMediaCenter = true;
        onSaveProduct('files', files.fieldType, files.isMultiple);
    }


    //------------------------ Inline content element methods --------------------------

    // Add content element
    const onAddContentElementHandler = (element: ContentElementItemType) => {
        setContentElements((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update content element
    const onContentElementUpdateHandler = (updateData: ContentElementItemType) => {
        // const foundIndex = contentElements.findIndex((item: ContentElementItemType) => item.id == updateData.id);
        // contentElements[foundIndex] = updateData;
        const updateContentElements = contentElements.map((el: ContentElementItemType) => {
            if (el.id == updateData.id) {
                return updateData
            } else {
                return el;
            }
        });
        setContentElements(updateContentElements);
    }

    // Remove content element
    const onContentElementRemoveHandler = (id: number | string) => {
        setContentElements((prevState: ContentElementItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Remove media from content element
    const onContentElementMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
    }

    // Brows media for content element
    const onContentElementMediaBrowseHandler = (item: any) => {
        fromMediaCenter = true;
        onSaveProduct('images', 'image', false, item.id, 'ContentElement');
    }


    //------------------------ Inline technical specifications methods --------------------------

    // Add technical option
    const onAddTechnicalSpecificationHandler = (element: TechnicalSpecificationItemType) => {
        setTechnicalSpecifications((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update technical option
    const onTechnicalSpecificationUpdateHandler = (updateData: TechnicalSpecificationItemType) => {
        // const foundIndex = technicalSpecifications.findIndex((item: TechnicalSpecificationItemType) => item.id == updateData.id);
        // technicalSpecifications[foundIndex] = updateData;
        const updateTechnicalSpecifications = technicalSpecifications.map((el: TechnicalSpecificationItemType) => {
            if (el.id == updateData.id) {
                return updateData
            } else {
                return el;
            }
        });
        setTechnicalSpecifications(updateTechnicalSpecifications);
    }

    // Remove technical option
    const onTechnicalSpecificationRemoveHandler = (id: number | string) => {
        setTechnicalSpecifications((prevState: TechnicalSpecificationItemType[]) => prevState.filter(item => item.id !== id));
    }


    //------------------------------- Tabs methods -------------------------------------

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    //--------------------------- Categories tree methods ------------------------------

    //Set tree handlerers
    const treeHandlerCheck = (checkedArr: any, targetNode: any) => {
        if (multipleSelect) {
            setChecked(checkedArr)
        } else {
            if (checkedArr.length != 0) {
                setChecked([targetNode.value.toString()])
            } else {
                setChecked([]);
            }
        }
    }
    const treeHandlerExpand = (event: any) => {
        setExpanded(event)
    }

    return (
        <React.Fragment>
            <Helmet title="Product"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active"  variant="outlined" avatar={<Avatar src={england} />} label="En" />
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={germany} />} label="De" />
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>
                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveProduct}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Related" {...tabsProps(1)} />
                                                    <Tab label="Additional" {...tabsProps(2)} />
                                                    <Tab label="Media" {...tabsProps(3)} />
                                                    <Tab label="Promotions" {...tabsProps(4)} />
                                                    <Tab label="Technical specifications" {...tabsProps(5)} />
                                                    <Tab label="Company" {...tabsProps(6)} />
                                                    <Tab label="Filter Criteria" {...tabsProps(7)} />
                                                </Tabs>
                                            </div>

                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Categories
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Tree
                                                        data={dataObj.categoriesThree}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={treeHandlerCheck}
                                                        onExpand={treeHandlerExpand}
                                                    />
                                                    <Grid container spacing={6} mt={5}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <RelatedProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <AdditionalProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Image
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onMediaBrowseHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Header images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={headerImagesItems}
                                                        fieldType={headerImages.fieldType}
                                                        allowedExtensions={headerImages.allowedExtensions}
                                                        isMultiple={headerImages.isMultiple}
                                                        onAdd={onAddHeaderImagesHandler}
                                                        onUpdate={onHeaderImagesUpdateHandler}
                                                        onDelete={onHeaderImagesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onHeaderImagesBrowseHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <VideoProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}
                                                        isBrowseMedia={true}
                                                        browseMedia={onFilesBrowseHandler}
                                                    />

                                                </TabPanel>

                                                <TabPanel value={tabValue} index={4}>
                                                    <PromotionProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Content Elements
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineContentElement
                                                        items={contentElements}
                                                        onAdd={onAddContentElementHandler}
                                                        onUpdate={onContentElementUpdateHandler}
                                                        onDelete={onContentElementRemoveHandler}
                                                        onMediaDelete={onContentElementMediaRemoveHandler}
                                                        onMediaBrowse={onContentElementMediaBrowseHandler}
                                                    />

                                                </TabPanel>

                                                <TabPanel value={tabValue} index={5}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Technical specifications
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineTechnicalSpecifications
                                                        items={technicalSpecifications}
                                                        types={dataObj.getTechnicalSpecificationSelect}
                                                        onAdd={onAddTechnicalSpecificationHandler}
                                                        onUpdate={onTechnicalSpecificationUpdateHandler}
                                                        onDelete={onTechnicalSpecificationRemoveHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <TechnicalSpecificationProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={6}>
                                                    <CompanyProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={7}>
                                                    <FiltersProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Product;
