import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, Divider as MuiDivider,
} from "@mui/material";

import ReactQuill from "react-quill";

// Styles
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {DatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const ProductMultiEditTranslate: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const platforms: { id: string, title: string }[] = data.selectPlatforms;
    const selectedPlatformsObj = state.platforms ? state.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : (values.platforms ? values.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>(selectedPlatformsObj);
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));
    const [publishDate, setPublishDate] = React.useState<Date | null>(state.publishDate ? state.publishDate : (values.publishDate ? values.publishDate : null));
    const [valueExportWeb, setValueExportWeb] = useState<boolean>(values.exportWeb?.length == 0 || !values.exportWeb ? false : values.exportWeb);


    //------------------------------- Public methods -------------------------------------

    // Update platforms on change
    const handlePlatformChange = (event: any, value: any) => {
        setSelectedPlatforms(value)
    }

    // Update description on change
    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };

    //update checkbox
    const handleExportWebChange = (event: any) => {
        setValueExportWeb(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>


            <Grid container spacing={6}>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitleDe"
                        label="Subtitle De"
                        value={values.subtitleDe ? values.subtitleDe : ''}
                        error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                        fullWidth
                        helperText={touched.subtitleDe && errors.subtitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitleDe"
                                    checked={state.clearSubtitleDe ? state.clearSubtitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>


                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Short description De"
                        name="shortDescriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescriptionDe ? values.shortDescriptionDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearShortDescriptionDe"
                                    checked={state.clearShortDescriptionDe ? state.clearShortDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Description De</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={descriptionDe}
                            onChange={(e) => {
                                handleChangeDescriptionDe(e);
                                onChange(e, 'descriptionDe')
                            }}
                        />
                    </QuillWrapper>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescription"
                                    checked={state.clearDescription ? state.clearDescription : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10} mt={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Notes
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Approvals & Compliances note De"
                        name="approvalsNoteDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.approvalsNoteDe ? values.approvalsNoteDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearApprovalsNoteDe"
                                    checked={state.clearApprovalsNoteDe ? state.clearApprovalsNoteDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Technical specifications note De"
                        name="technicalSpecificationsNoteDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.technicalSpecificationsNoteDe ? values.technicalSpecificationsNoteDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTechnicalSpecificationsNoteDe"
                                    checked={state.clearTechnicalSpecificationsNoteDe ? state.clearTechnicalSpecificationsNoteDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10} mt={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                Seo
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="seoTitleDe"
                        label="Seo Title De"
                        value={values.seoTitleDe ? values.seoTitleDe : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoTitleDe"
                                    checked={state.clearSeoTitleDe ? state.clearSeoTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5} />
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Description De"
                        name="seoDescriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescriptionDe ? values.seoDescriptionDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoDescriptionDe"
                                    checked={state.clearSeoDescriptionDe ? state.clearSeoDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Keywords De"
                        name="seoKeywordsDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywordsDe ? values.seoKeywordsDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoKeywordsDe"
                                    checked={state.clearSeoKeywordsDe ? state.clearSeoKeywordsDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>


            </Grid>
        </React.Fragment>
    );
}


export default ProductMultiEditTranslate;
