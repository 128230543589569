import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const PlatformMultiEditTranslate: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange, state, multiEdit} = props;

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
        </React.Fragment>
    );
}


export default PlatformMultiEditTranslate;
