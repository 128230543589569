import React, {useState} from "react";

import {
    Grid,
    Typography,
    Checkbox,
    TextField,
    Divider as MuiDivider,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    FormControl
} from "@mui/material";
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styling
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
const Divider = styled(MuiDivider)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const FiltersProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const supply: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'supply');
    const selectedSupplyObj = state.supply ? state.supply.map((id: string) => supply.filter((item) => item.id === id)[0]) : (values.supply ? values.supply.map((id: string) => supply.filter((item) => item.id === id)[0]) : []);
    const productType: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'productType');
    const selectedProductTypeObj = state.productType ? state.productType  : (values.productType ? values.productType[0] : '');

    // States
    const [selectedSupply, setSelectedSupply] = useState<any[]>(selectedSupplyObj);
    const [selectedProductType, setSelectedProductType] = useState<any[]>(selectedProductTypeObj);

    //------------------------------- Public methods -------------------------------------

    // Update supply on change
    const handleSupplyChange = (value: any) => {
        setSelectedSupply(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General config
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={supply}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedSupply || []}
                        defaultValue={selectedSupply || []}
                        onChange={(event: any, value: any) => {
                            handleSupplyChange(value);
                            onChange(event, 'supply', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Supply" placeholder="Supply" name="supply"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSupply"
                                    checked={state.clearSupply ? state.clearSupply : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Product type</InputLabel>
                        <Select
                            name="productType"
                            value={selectedProductType}
                            label="Product type"
                            onChange={(event: any) => {
                                setSelectedProductType(event.target.value);
                                onChange(event)
                            }}
                        >
                            {productType.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearProductType"
                                    checked={state.clearProductType ? state.clearProductType : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config rated
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageFrom"
                        label="Voltage from"
                        value={values.voltageFrom ? values.voltageFrom : ''}
                        error={Boolean(touched.voltageFrom && errors.voltageFrom)}
                        fullWidth
                        helperText={touched.voltageFrom && errors.voltageFrom}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVoltageFrom"
                                    checked={state.clearVoltageFrom ? state.clearVoltageFrom : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageTo"
                        label="Voltage to"
                        value={values.voltageTo ? values.voltageTo : ''}
                        error={Boolean(touched.voltageTo && errors.voltageTo)}
                        fullWidth
                        helperText={touched.voltageTo && errors.voltageTo}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearVoltageTo"
                                    checked={state.clearVoltageTo ? state.clearVoltageTo : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default FiltersProduct;
