import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ContactInfoCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Contact info
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={10}>
                    <TextField
                        name="person"
                        label="Person"
                        value={values.person ? values.person : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPerson"
                                    checked={state.clearPerson ? state.clearPerson : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="mobile"
                        label="Mobile"
                        value={values.mobile ? values.mobile : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearMobile"
                                    checked={state.clearMobile ? state.clearMobile : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="phone"
                        label="Phone"
                        value={values.phone ? values.phone : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearPhone"
                                    checked={state.clearPhone ? state.clearPhone : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="fax"
                        label="Fax"
                        value={values.fax ? values.fax : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearFax"
                                    checked={state.clearFax ? state.clearFax : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="email"
                        label="Email"
                        value={values.email ? values.email : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearEmail"
                                    checked={state.clearEmail ? state.clearEmail : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="website"
                        label="Website"
                        value={values.website ? values.website : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearWebsite"
                                    checked={state.clearWebsite ? state.clearWebsite : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default ContactInfoCompany;
