import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const AdditionalsCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Additional info
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="additional"
                        label="Additional"
                        value={values.additional ? values.additional : ''}
                        fullWidth
                        multiline
                        rows={4}
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearAdditional"
                                    checked={state.clearAdditional ? state.clearAdditional : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AdditionalsCompany;
