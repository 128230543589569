import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider, InputLabel,
} from "@mui/material";
import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;

const AdditionalProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;



    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Approvals
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Approvals & Compliances note De"
                        name="approvalsNoteDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.approvalsNoteDe ? values.approvalsNoteDe : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Approvals & Compliances note"
                        name="approvalsNote"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.approvalsNote ? values.approvalsNote : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

            </Grid>



        </React.Fragment>
    );
}

export default AdditionalProduct;
