import React, {ChangeEvent, useState} from "react";
import {
    Grid, IconButton, TextField as MuiTextField,
} from "@mui/material";


// Types
import {DynamicSelectOption} from "../../../../types/dynamicSelectOption";

// Icons
import {Trash2 as TrashIcon} from "react-feather";


// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DynamicOptionItem: React.FC<{ item: DynamicSelectOption, onUpdate: (data: any) => void, onDelete: (data: any) => void }> = (props) => {
    const [item, setItem] = useState(props.item);

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };

    // Trigger delete
    const deleteHandler = () => {
        props.onDelete(item);
    };


    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item md={5} mb={5}>
                    <TextField
                        name="title"
                        label="Title"
                        value={item.title}
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                        onBlur={() => updateHandler()}
                        variant="outlined"
                        my={2}
                        required
                    />
                </Grid>
                <Grid item md={5} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={item.titleDe}
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                        onBlur={() => updateHandler()}
                        variant="outlined"
                        my={2}
                    />
                </Grid>

                <Grid item md={1} mb={5}>
                    <TextField
                        name="sorting"
                        label="Order"
                        type="number"
                        value={item.sorting}
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => inputHandler(e)}
                        onBlur={() => updateHandler()}
                        variant="outlined"
                        InputProps={{inputProps: {min: 0}}}
                        my={2}
                    />
                </Grid>

                <Grid item mb={5}>
                    <IconButton aria-label="delete" size="large"
                                onClick={() => deleteHandler()}>
                        <TrashIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default DynamicOptionItem;
