import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    TableContainer, Table, TableBody, TableRow, TableCell,
    Alert as MuiAlert, IconButton
} from "@mui/material";


import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";


// GraphQL
import {FileQuery, useFileQuery} from "../../graphql/mediacenter/mediacenter.graphql-gen";


// Icons
import {Download, Save as SaveIcon, Trash2 as TrashIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import Avatar from "@mui/material/Avatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {KeyboardReturn, RemoveRedEye as RemoveRedEyeIcon} from "@mui/icons-material";
import Lightbox from "react-image-lightbox";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const File: React.FC = () => {
    let title: string = 'File'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const location: any = useLocation();

    const [parentId, setParentId] = useState<any>('');

    // Queries
    const {data: data, error: error, loading: loading} = useFileQuery({
        variables: {id},
        onCompleted: (data: FileQuery) => {
            if(data && data.getFile){
                setParentId(data.getFile.file?.folder);
            }

        }
    });


    // States
    const [loader, setLoader] = useState<boolean>(false);
    const [lightbox, setLightbox] = useState(false);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }


    //------------------------------- Public methods -------------------------------------


    //Back to parent folder
    const backToListView = () => {
        if(parentId){
            navigate(`/mediacenter/folder/${parentId}`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
        }else{
            navigate(`/mediacenter`, {replace: true, state:{fieldName: location.state?.fieldName, fieldType: location.state?.fieldType, isMultiple: location.state?.isMultiple, modelName: location.state?.modelName, modelId: location.state?.modelId, modelIds: location.state?.modelIds, fromMediaCenter: true, submodelName: location.state?.submodelName, submodelId: location.state?.submodelId}})
        }
    }

    // Preview image in lightbox
    const onPreview = () => {
        setLightbox(true);
    };

    // File download
    const download = (e: any, fileName: string, mimeType: string, name: string) => {
        e.preventDefault();
        fetch(fileName, {
            method: 'GET',
            headers: {
                'Content-Type': mimeType,
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name,
                );
                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                if(link.parentNode){
                    link.parentNode.removeChild(link);
                }

            });
    };

    return (
        <React.Fragment>
            <Helmet title="File"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="primary" onClick={backToListView}
                                className="multi-btn" startIcon={<KeyboardReturn/>}>
                            Back
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {dataObj &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className="file-show">
                                    <Grid container spacing={6} alignItems="center">
                                        <Grid item xs={2} md={1}>
                                            {dataObj.getFile.file.thumbnail == ''
                                                ? <Avatar><AssignmentIcon/></Avatar>
                                                : <Avatar src={dataObj.getFile.file.thumbnail}/>
                                            }
                                        </Grid>
                                        <Grid item xs={10} md={11}>
                                            {dataObj.getFile.file.thumbnail == ''
                                                ? <IconButton aria-label="details" size="large" href={dataObj.getFile.file.path} target="_blank"> <RemoveRedEyeIcon/> </IconButton>
                                                : <IconButton aria-label="details" size="large" onClick={() => onPreview()}> <RemoveRedEyeIcon/> </IconButton>
                                            }

                                            <a href={dataObj.getFile.file.path} download onClick={e => download(e, dataObj.getFile.file.path, dataObj.getFile.file.mimeType, dataObj.getFile.file.title)}
                                            >
                                                <IconButton aria-label="download" size="large">
                                                    <Download/>
                                                </IconButton>
                                            </a>
                                        </Grid>
                                    </Grid>

                                    <div className="file-more">
                                        <TableContainer>
                                            <Table sx={{maxWidth: 650}} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Title</TableCell>
                                                        <TableCell>{dataObj.getFile.file.title}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Size</TableCell>
                                                        <TableCell>{dataObj.getFile.file.size}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">Extension</TableCell>
                                                        <TableCell>{dataObj.getFile.file.extension}</TableCell>
                                                    </TableRow>
                                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                        <TableCell component="th" scope="row">MimeType</TableCell>
                                                        <TableCell>{dataObj.getFile.file.mimeType}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }

            {lightbox && (
                <Lightbox
                    mainSrc={dataObj.getFile.file.path}
                    onCloseRequest={() => setLightbox(false)}
                />
            )}
        </React.Fragment>
    );
}

export default File;
