import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel, FormGroup, FormControlLabel, Checkbox, Divider as MuiDivider,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const CategoryMultiEditTranslate: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    // States
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));

    //------------------------------- Public methods -------------------------------------

    // Update description on change

    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant="h6" mb={5}>
                        General informations
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearTitleDe"
                                    checked={state.clearTitleDe ? state.clearTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitleDe"
                        label="Subtitle De"
                        value={values.subtitleDe ? values.subtitleDe : ''}
                        error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                        fullWidth
                        helperText={touched.subtitleDe && errors.subtitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSubtitleDe"
                                    checked={state.clearSubtitleDe ? state.clearSubtitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12} md={12} mb={5}>
                    <TextField
                        label="Short description De"
                        name="shortDescriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescriptionDe ? values.shortDescriptionDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearShortDescriptionDe"
                                    checked={state.clearShortDescriptionDe ? state.clearShortDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={12} mb={10}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Description De</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={descriptionDe}
                            onChange={(e) => {
                                handleChangeDescriptionDe(e);
                                onChange(e, 'descriptionDe')
                            }}
                        />
                    </QuillWrapper>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearDescriptionDe"
                                    checked={state.clearDescriptionDe ? state.clearDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Typography variant="h6" mb={5}>
                        Promotions
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Seo Title De"
                            name="seoTitleDe"
                            value={values.seoTitleDe ? values.seoTitleDe : ''}
                            error={Boolean(touched.seoTitleDe && errors.seoTitleDe)}
                            fullWidth
                            helperText={touched.seoTitleDe && errors.seoTitleDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoTitleDe"
                                    checked={state.clearSeoTitleDe ? state.clearSeoTitleDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Seo Description De"
                            name="seoDescriptionDe"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.seoDescriptionDe ? values.seoDescriptionDe : ''}
                            onChange={onChange}
                        />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoDescriptionDe"
                                    checked={state.clearSeoDescriptionDe ? state.clearSeoDescriptionDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Keywords De"
                        name="seoKeywordsDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywordsDe ? values.seoKeywordsDe : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoKeywordsDe"
                                    checked={state.clearSeoKeywordsDe ? state.clearSeoKeywordsDe : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>

        </React.Fragment>
    );
}


export default CategoryMultiEditTranslate;
