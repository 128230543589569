import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Loader from "../../../components/Loader";
import {
    Alert as MuiAlert, CircularProgress,
} from "@mui/material";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

// Types
import {dynamicListView} from "../../../types/dynamicListView";

// Graphql
import {
    ProductsListQuery,
    useDeleteProductMutation,
    useExportCsvProductMutation, useGeneratePdfProductsMutation, useImportCsvProductMutation,
    useProductsListQuery
} from "../../../graphql/product/product.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Filters} from "../../../types/filters";
import SnackbarNotification from "../../../components/SnackbarNotification";
import {ApolloQueryResult} from "@apollo/client";
import {ImportsListQuery} from "../../../graphql/settings/import/import.graphql-gen";


const Alert = styled(MuiAlert)(spacing);


export const ProductsContext = React.createContext<dynamicListView>({
    items: [],
    headers: [],
    count: 0,
    addItem: () => {},
    removeItem: (id: string) => {},
    selectItem: (id: string | number) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText:string) => {},
    onSort: (filed:string, order: string) => {},
    onFilter: (values: any) => {},
    page: 0,
    rowsPerPage: 10,
    search: '',
    sorting: {field: '', order: ''},
    filters: [],
    filter: {},
    multiSelected: (idArray: string[]) => {},
    multiEdit: () => {},
    selected: [],
    exportCsv: (exportType: string) => {},
    importCsv: (event: any) => {}
});

const ProductsContextProvider: React.FC = (props) => {
    const model: string = 'Product';

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [id, setId] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: 'updated_at', order: 'desc'});
    const [filters, setFilters] = useState<Filters[]>([]);
    const [filter, setFilter] = useState<any>({} );
    const [selectedArray, setSelectedArray] = useState<any[]>([]);
    const [updated, setUpdated] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [message, setMessage] = useState('');
    const [errorType, setErrorType] = useState<any>('success');
    const [preloder, setPreloder] = useState(false);

    // Get products data from API
    const {data, error, loading, refetch: refetchListViewData} = useProductsListQuery({
        variables: {
            model,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            }
        }, onCompleted: () => {
            if (data) {
                setHeaders(data.getDynamicTable.tableHeader);
                setItems(data.getDynamicTable.tableData);
                setCurrentPage(data.getDynamicTable.page);
                setItemsPerPage(data.getDynamicTable.limit);
                setCount(data.getDynamicTable.count);
                setSearch(data.getDynamicTable.search);
                setFilters(data.getDynamicTable.filters);
                setFilter(data.getDynamicTable.filter);
                setSorting(data.getDynamicTable.sorting);
            }
        }
    });
    const [deleteProduct] = useDeleteProductMutation();
    const [exportCsvProduct] = useExportCsvProductMutation();
    const [importCsvProduct] = useImportCsvProductMutation();
    const [generatePdfProducts] = useGeneratePdfProductsMutation();

    if (loading) {
        return <Loader/>;
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------


    // Handle add
    const addItemHandler = () => {
        navigate(`/products/new`, {replace: true});
    };

    // Handel delete
    const removeItemHandler = (id: string) => {
        if (id) {
            setId(id);
        }
        setOpen(true);

    }

    // Go to singe view
    const selectItemHandler = (id: string | number) => {
        navigate(`/products/${id}`, {replace: true});
    };

    // Delete mutation
    const deleteProductMutation = (id: string) => {
        setOpen(false);

        deleteProduct({
            variables: {
                id
            }
        }).then((response: any) => {
            refetchListViewData()
                .then((res:ApolloQueryResult<ProductsListQuery>) => {
                if (res) {
                    setHeaders(res.data.getDynamicTable.tableHeader);
                    setItems(res.data.getDynamicTable.tableData);
                    setCurrentPage(res.data.getDynamicTable.page);
                    setItemsPerPage(res.data.getDynamicTable.limit);
                    setCount(res.data.getDynamicTable.count);
                    setSearch(res.data.getDynamicTable.search);
                    setFilters(res.data.getDynamicTable.filters);
                    setSorting(res.data.getDynamicTable.sorting);
                }
            });
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    //Export CSV
    const exportCsvHandler = (exportType: string) => {
        exportCsvProduct({
            variables: {
                model,
                exportType: exportType,
                ids: selectedArray
            }
        }).then((res: any) => {
            refetchListViewData();
            window.location.href = res.data.exportModelData;
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    //Import CSV
    const importCsvHandler = (event: any) => {
        setLoadingCsv(true)
        const file = event;
        if (!file) return;

        importCsvProduct({
            variables: {
                model,
                file: file
            }
        }).then((res: any) => {
            setLoadingCsv(false)
            refetchListViewData();
            setErrorType('success');
            setMessage('Import completed successfully');
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false);
            }, 3000)
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            model,
            search,
            filter,
            pagination: {
                limit: limit,
                page: page
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });

        setItemsPerPage(limit);
        setCurrentPage(page);
    };


    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            model,
            search: searchText,
            filter,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            model,
            search,
            filter,
            pagination: {
                limit: itemsPerPage,
                page: 0
            },
            sorting: {
                field,
                order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // filter
    const onFilterHandler = (values: any) => {

        refetchListViewData({
            model,
            search,
            filter: values,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
        setFilter(values);
    };

    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // multiselect
    const multiSelectedHendler = (idArray: string[]) => {
        setSelectedArray(idArray)
    }
    const multiEditHendler = () => {
        navigate(`/products/multi-edit`, {replace: true, state:{ids:selectedArray}});
    }

    //generate Pdf
    const generatePdfHendler = () => {
        setPreloder(true);
        generatePdfProducts({
            variables: {
                model,
                ids: selectedArray
            }
        }).then((res: any) => {
            setSelectedArray([]);
            if (res.data.generatePdf) {
                setMessage('You successfully generate pdf');
                setErrorType('success');
            } else {
                setMessage('Error, please try again later.');
                setErrorType('error');
            }
            setUpdated(true);
            refetchListViewData();
            setPreloder(false);
            setTimeout(() => {
                setUpdated(false);
            }, 3000);
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Set context values
    const contextValue: dynamicListView = {
        items: items,
        headers: headers,
        count: count,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        selectItem: selectItemHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        onFilter: onFilterHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        sorting: sorting,
        filters: filters,
        filter: filter,
        multiEdit: multiEditHendler,
        multiSelected: multiSelectedHendler,
        selected: selectedArray,
        generatePdf: generatePdfHendler,
        exportCsv: exportCsvHandler,
        importCsv: importCsvHandler
    };

    return (
        <ProductsContext.Provider value={contextValue}>
            {props.children}

            {updated &&
                <SnackbarNotification message={message} open={updated} type={errorType}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to remove this? This action cannot be undone!"
                title="Delete"
                button="Delete"
                open={open}
                onConfirm={() => deleteProductMutation(id)}
                onCancel={closeDialog}/>
            {preloder &&
                <div className="preloader">
                    <CircularProgress color="secondary"/>
                </div>
            }
        </ProductsContext.Provider>
    );
};

export default ProductsContextProvider;
