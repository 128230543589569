import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {PlatformsContext} from "./store/platforms-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Platforms() {
    const platformsCtx = useContext(PlatformsContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = platformsCtx.headers;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Platforms"/>
            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    Platforms
                </Typography>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10} mb={10}>
                <Grid item>
                    <SearchBar text={platformsCtx.search} onSearch={platformsCtx.onSearch}/>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={platformsCtx.addItem} startIcon={<AddIcon/>}>
                            New Platform
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (platformsCtx.exportCsv) {
                                    platformsCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV Export Platforms
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (platformsCtx.exportCsv) {
                                    platformsCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Platforms
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS Import Platforms
                            </MenuItem>

                            {platformsCtx.selected && platformsCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={platformsCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Multi Edit
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (platformsCtx.importCsv) {
                                    platformsCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (platformsCtx.exportCsv) {*/}
                        {/*        platformsCtx.exportCsv('csv')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    CSV Export Platforms*/}
                        {/*</Button>*/}
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (platformsCtx.exportCsv) {*/}
                        {/*        platformsCtx.exportCsv('xls')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    XLS Export Platforms*/}
                        {/*</Button>*/}
                        {/*<Input*/}
                        {/*    ref={fileInput}*/}
                        {/*    accept=".csv,.xlsx,.xls"*/}
                        {/*    type="file"*/}
                        {/*    name="file"*/}
                        {/*    onChange={(event: any) => {*/}
                        {/*        if (platformsCtx.importCsv) {*/}
                        {/*            platformsCtx.importCsv(event.target.files)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button variant="contained" color="primary" onClick={(e: any) => {*/}
                        {/*    if (fileInput.current) {*/}
                        {/*        fileInput.current.click();*/}
                        {/*    }*/}
                        {/*}} startIcon={<FileUpload/>} className="margin-right-btn">*/}
                        {/*    CSV/XLS Import Platforms*/}
                        {/*</Button>*/}
                        {/*{platformsCtx.selected && platformsCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={platformsCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >*/}
                        {/*        Multi edit*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button variant="contained" color="primary" onClick={platformsCtx.addItem}>*/}
                        {/*    <AddIcon/>*/}
                        {/*    New Platform*/}
                        {/*</Button>*/}
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={platformsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={platformsCtx.removeItem}
                    onSelectHandler={platformsCtx.selectItem}
                    onPaginateHandler={platformsCtx.onPaginate}
                    onSortHandler={platformsCtx.onSort}
                    count={platformsCtx.count}
                    page={platformsCtx.page}
                    rowsPerPage={platformsCtx.rowsPerPage}
                    sorting={platformsCtx.sorting}
                    filters={platformsCtx.filters}
                    onMultiEditHandler={platformsCtx.multiSelected}
                    selectedArray = {platformsCtx.selected}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Platforms;
