import React, {useState} from "react";

import {
    Grid,
    Typography,
    Checkbox, Autocomplete, TextField, FormGroup, FormControlLabel,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const CompanyProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const companies: { id: string, title: string }[] = data.selectCompanies;
    const selectedCompaniesObj = state.companies ? state.companies.map((id: string) => companies.filter((item) => item.id === id)[0]) : (values.companies ? values.companies.map((id: string) => companies.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedCompanies, setSelectedCompanies] = useState<any[]>(selectedCompaniesObj);

    //------------------------------- Public methods -------------------------------------

    // Update Companies on change
    const handleCompaniesChange = (value: any) => {
        setSelectedCompanies(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Company
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={companies}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedCompanies || []}
                        defaultValue={selectedCompanies || []}
                        onChange={(event: any, value: any) => {
                            handleCompaniesChange(value);
                            onChange(event, 'companies', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Companies" placeholder="Companies" name="companies"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCompanies"
                                    checked={state.clearCompanies ? state.clearCompanies : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

            </Grid>
        </React.Fragment>
    );
}


export default CompanyProduct;
