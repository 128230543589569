import React, {ChangeEvent, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab,
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import GeneralInfoPlatform from "./fieldGroups/GeneralInfoPlatform";
import TabPanel from "../components/TabPanel";

// GraphQL
import {FetchResult} from "@apollo/client";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {
    CreatePlatformMutation, NewPlatformQuery, PlatformMultiEditQuery,
    PlatformQuery,
    useCreatePlatformMutation, useMultiUpdatePlatformMutation, useNewPlatformQuery, usePlatformMultiEditQuery,
    usePlatformQuery,
    useUpdatePlatformMutation
} from "../../graphql/platform/platform.graphql-gen";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import {useCategoryMultiEditQuery} from "../../graphql/category/category.graphql-gen";
import PlatformMultiEditTranslate from "./fieldGroups/translate/PlatformMultiEditTranslate";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    //title: Yup.string().required("Required"),
});

const PlatformMultiEdit: React.FC = () => {
    let title: string = 'Platform Multi Edit'
    const model: string = 'Platform'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const location: any = useLocation();

    // Queries
    const {data: data, error: error, loading: loading} = usePlatformMultiEditQuery({
        variables: {ids: location.state.ids, model},
        onCompleted: (data: PlatformMultiEditQuery) => {
            setState({});
            setInicialValue({});
            //setUserId(data.user.user?.id);
        }
    });


    const [multiUpdatePlatform] = useMultiUpdatePlatformMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();

    useEffect(() => {
        if (id === '') {
            setState({});
            setInicialValue({});
        }
    }, []);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSavePlatform = () => {
        updatePlatformMultiEditMutation(state);
        setInicialValue(state);
    }

    // Update mutation
    const updatePlatformMultiEditMutation = (data: any) => {
        multiUpdatePlatform({
            variables: {
                model,
                ids: location.state.ids,
                data
            }
        })
            .then(r => {
                setUpdated(true)
                setTimeout(() => {
                    setUpdated(false);
                }, 3000)

            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        navigate(`/platforms`, {replace: true})
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, state);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {
            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Category"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                    <Typography variant="h4" gutterBottom >
                        {dataObj.platformMultiEdit.map((item: any, index: number) => {
                            if(item.platform ){
                                return item.platform.title
                            }
                        }).join(', ')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSavePlatform}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Translate Geramny" {...tabsProps(1)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoPlatform
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <PlatformMultiEditTranslate
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default PlatformMultiEdit;
