import * as Types from '../../../generated/types.graphql-gen.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Json scalar */
  Json: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type Approval = {
  __typename?: 'Approval';
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type ApprovalArray = {
  __typename?: 'ApprovalArray';
  approval?: Maybe<ApprovalData>;
  image?: Maybe<InlineMedia>;
};

export type ApprovalData = {
  __typename?: 'ApprovalData';
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Article = {
  __typename?: 'Article';
  articleNo?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  product?: Maybe<Product>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type ArticleArray = {
  __typename?: 'ArticleArray';
  article?: Maybe<ArticleData>;
  files?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
};

export type ArticleData = {
  __typename?: 'ArticleData';
  articleNo?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  expDate?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Category = {
  __typename?: 'Category';
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Category>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type CategoryArray = {
  __typename?: 'CategoryArray';
  category?: Maybe<CategoryData>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  files?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  multiElement?: Maybe<CategoryData>;
};

export type CategoryData = {
  __typename?: 'CategoryData';
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  additional?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<State>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyArray = {
  __typename?: 'CompanyArray';
  company?: Maybe<CompanyData>;
  logo?: Maybe<InlineMedia>;
};

export type CompanyData = {
  __typename?: 'CompanyData';
  additional?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['ID']>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ConfHeader = {
  __typename?: 'ConfHeader';
  id?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type DataApproval = {
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearImage?: InputMaybe<Scalars['Boolean']>;
  clearLink?: InputMaybe<Scalars['Boolean']>;
  clearLinkDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataArticle = {
  articleNo?: InputMaybe<Scalars['String']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProduct?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  product?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataCategory = {
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearFiles?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywordsDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearShortDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearSubtitleDe?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['ID']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
};

export type DataCompany = {
  additional?: InputMaybe<Scalars['String']>;
  additionalDe?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityDe?: InputMaybe<Scalars['String']>;
  clearAdditional?: InputMaybe<Scalars['Boolean']>;
  clearAdditionalDe?: InputMaybe<Scalars['Boolean']>;
  clearBuilding?: InputMaybe<Scalars['Boolean']>;
  clearCity?: InputMaybe<Scalars['Boolean']>;
  clearCityDe?: InputMaybe<Scalars['Boolean']>;
  clearCompanySolutions?: InputMaybe<Scalars['Boolean']>;
  clearCompanyType?: InputMaybe<Scalars['Boolean']>;
  clearCountries?: InputMaybe<Scalars['Boolean']>;
  clearEmail?: InputMaybe<Scalars['Boolean']>;
  clearFax?: InputMaybe<Scalars['Boolean']>;
  clearLatitude?: InputMaybe<Scalars['Boolean']>;
  clearLogo?: InputMaybe<Scalars['Boolean']>;
  clearLongitude?: InputMaybe<Scalars['Boolean']>;
  clearMobile?: InputMaybe<Scalars['Boolean']>;
  clearPerson?: InputMaybe<Scalars['Boolean']>;
  clearPhone?: InputMaybe<Scalars['Boolean']>;
  clearStates?: InputMaybe<Scalars['Boolean']>;
  clearStreet?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearWebsite?: InputMaybe<Scalars['Boolean']>;
  clearZip?: InputMaybe<Scalars['Boolean']>;
  companySolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  street?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DataContentElement = {
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  label?: InputMaybe<Scalars['String']>;
  labelDe?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  videoDe?: InputMaybe<Scalars['String']>;
};

export type DataFolder = {
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataImport = {
  clearFile?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  cronSchedule?: InputMaybe<Scalars['String']>;
  enclosure?: InputMaybe<Scalars['String']>;
  entity?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  imagesFileDirectory?: InputMaybe<Scalars['String']>;
  importSource?: InputMaybe<Scalars['String']>;
  separator?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataMedia = {
  altTitle?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataModel = {
  fieldName?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<Scalars['String']>;
  fromMediaCenter?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  isMultiple?: InputMaybe<Scalars['Boolean']>;
  modelId?: InputMaybe<Scalars['ID']>;
  modelIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  modelName?: InputMaybe<Scalars['String']>;
  submodelId?: InputMaybe<Scalars['String']>;
  submodelName?: InputMaybe<Scalars['String']>;
};

export type DataOption = {
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  textDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataPlatform = {
  clearCode?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataProduct = {
  accessories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvals?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvalsNote?: InputMaybe<Scalars['String']>;
  approvalsNoteDe?: InputMaybe<Scalars['String']>;
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  clearAccessories?: InputMaybe<Scalars['Boolean']>;
  clearApprovals?: InputMaybe<Scalars['Boolean']>;
  clearApprovalsNote?: InputMaybe<Scalars['Boolean']>;
  clearApprovalsNoteDe?: InputMaybe<Scalars['Boolean']>;
  clearCategories?: InputMaybe<Scalars['Boolean']>;
  clearCompanies?: InputMaybe<Scalars['Boolean']>;
  clearContentElements?: InputMaybe<Scalars['Boolean']>;
  clearDescription?: InputMaybe<Scalars['Boolean']>;
  clearDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearFiles?: InputMaybe<Scalars['Boolean']>;
  clearHeaderImages?: InputMaybe<Scalars['Boolean']>;
  clearImages?: InputMaybe<Scalars['Boolean']>;
  clearParent?: InputMaybe<Scalars['Boolean']>;
  clearPlatforms?: InputMaybe<Scalars['Boolean']>;
  clearProductType?: InputMaybe<Scalars['Boolean']>;
  clearPublishDate?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescription?: InputMaybe<Scalars['Boolean']>;
  clearSeoDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywords?: InputMaybe<Scalars['Boolean']>;
  clearSeoKeywordsDe?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitle?: InputMaybe<Scalars['Boolean']>;
  clearSeoTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearShortDescription?: InputMaybe<Scalars['Boolean']>;
  clearShortDescriptionDe?: InputMaybe<Scalars['Boolean']>;
  clearSorting?: InputMaybe<Scalars['String']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']>;
  clearSubtitleDe?: InputMaybe<Scalars['Boolean']>;
  clearSupply?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecifications?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecificationsNote?: InputMaybe<Scalars['Boolean']>;
  clearTechnicalSpecificationsNoteDe?: InputMaybe<Scalars['Boolean']>;
  clearTitleDe?: InputMaybe<Scalars['Boolean']>;
  clearVideo?: InputMaybe<Scalars['Boolean']>;
  clearVoltageFrom?: InputMaybe<Scalars['Boolean']>;
  clearVoltageTo?: InputMaybe<Scalars['Boolean']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  publishDate?: InputMaybe<Scalars['String']>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  supply?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: InputMaybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  voltageFrom?: InputMaybe<Scalars['String']>;
  voltageTo?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalOption = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalSpecification = {
  id: Scalars['ID'];
  options?: InputMaybe<Array<InputMaybe<DataTechnicalOption>>>;
  sorting?: InputMaybe<Scalars['String']>;
  type: Scalars['ID'];
};

export type DataUser = {
  crUser?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type DataUserGroup = {
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  extension?: Maybe<Scalars['String']>;
  folder?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  mimeType?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FileArray = {
  __typename?: 'FileArray';
  file?: Maybe<File>;
};

export type FileFields = {
  __typename?: 'FileFields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  importId?: Maybe<Scalars['ID']>;
};

export type Filter = {
  fieldName?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<FilterOption>>>;
  selectedOption?: InputMaybe<FilterOption>;
};

export type FilterOption = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Folder = {
  __typename?: 'Folder';
  id?: Maybe<Scalars['ID']>;
  parent?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FolderArray = {
  __typename?: 'FolderArray';
  errorMessage?: Maybe<Scalars['String']>;
  folder?: Maybe<Folder>;
};

export type Import = {
  __typename?: 'Import';
  crUser?: Maybe<User>;
  cronSchedule?: Maybe<Scalars['String']>;
  enclosure?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imagesFileDirectory?: Maybe<Scalars['String']>;
  importSource?: Maybe<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  validImportFile?: Maybe<Scalars['Boolean']>;
};

export type ImportArray = {
  __typename?: 'ImportArray';
  file?: Maybe<InlineMedia>;
  import?: Maybe<ImportData>;
};

export type ImportData = {
  __typename?: 'ImportData';
  crUser?: Maybe<Scalars['ID']>;
  cronSchedule?: Maybe<Scalars['String']>;
  enclosure?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  imagesFileDirectory?: Maybe<Scalars['String']>;
  importSource?: Maybe<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  validImportFile?: Maybe<Scalars['Boolean']>;
};

export type InitInlineMedia = {
  __typename?: 'InitInlineMedia';
  field: Scalars['String'];
  inlineMedia: InlineMedia;
};

export type InlineContentElement = {
  __typename?: 'InlineContentElement';
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<MediaItem>>>;
  label?: Maybe<Scalars['String']>;
  labelDe?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
};

export type InlineMedia = {
  __typename?: 'InlineMedia';
  allowedExtensions?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MediaItem>>>;
};

export type InlineOptions = {
  __typename?: 'InlineOptions';
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  textDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type InlineTechnicalSpecification = {
  __typename?: 'InlineTechnicalSpecification';
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<TechnicalOption>>>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
};

export type MappedFields = {
  __typename?: 'MappedFields';
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  importId?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  mappedField?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  unique?: Maybe<Scalars['Boolean']>;
};

export type MappedFieldsData = {
  field?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  importId?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  mappedField?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  unique?: InputMaybe<Scalars['Boolean']>;
};

export type MediaItem = {
  __typename?: 'MediaItem';
  altTitle?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApproval: ApprovalArray;
  createArticle: ArticleArray;
  createCategory: CategoryArray;
  createCompany: CompanyArray;
  createFile: Scalars['Boolean'];
  createFolder: FolderArray;
  createImport: ImportArray;
  createPlatform: PlatformArray;
  createProduct: ProductArray;
  createReference: ReferenceArray;
  createUser?: Maybe<UserArray>;
  createUserGroup: UserGroupArray;
  deleteApproval: Approval;
  deleteArticle?: Maybe<Article>;
  deleteCategory?: Maybe<Category>;
  deleteCompany: Company;
  deleteFile?: Maybe<File>;
  deleteFolder?: Maybe<Folder>;
  deleteImport: Import;
  deletePlatform: Platform;
  deleteProduct?: Maybe<Product>;
  deleteUser?: Maybe<User>;
  deleteUserGroup: UserGroup;
  exportApprovals: Scalars['String'];
  exportArticles: Scalars['String'];
  exportCategories: Scalars['String'];
  exportCompanies: Scalars['String'];
  exportModelData: Scalars['String'];
  exportPlatforms: Scalars['String'];
  exportProducts: Scalars['String'];
  forgotPassword?: Maybe<Scalars['Boolean']>;
  generatePdf: Scalars['Boolean'];
  importArticles: Scalars['Boolean'];
  importCategories: Scalars['Boolean'];
  importModelData: Scalars['Boolean'];
  inlineMediaUpload: Array<Maybe<MediaItem>>;
  multiResetApproval: Scalars['Boolean'];
  multiResetArticle: Scalars['Boolean'];
  multiResetCategory: Scalars['Boolean'];
  multiResetCompany: Scalars['Boolean'];
  multiResetProduct: Scalars['Boolean'];
  multiUpdateApproval: ApprovalArray;
  multiUpdateArticle: ArticleArray;
  multiUpdateCategory: CategoryArray;
  multiUpdateCompany: CompanyArray;
  multiUpdatePlatform: PlatformArray;
  multiUpdateProduct: ProductArray;
  refreshAccessToken: AuthUser;
  resetApproval: Scalars['Boolean'];
  resetArticle: Scalars['Boolean'];
  resetCategory: Scalars['Boolean'];
  resetCompany: Scalars['Boolean'];
  resetImport: Scalars['Boolean'];
  resetPassword?: Maybe<Scalars['Boolean']>;
  resetProduct: Scalars['Boolean'];
  resetProfilePassword?: Maybe<ResetRespos>;
  runImport?: Maybe<Scalars['Boolean']>;
  signIn: AuthUser;
  signOut?: Maybe<Scalars['Boolean']>;
  updateApproval: ApprovalArray;
  updateArticle: ArticleArray;
  updateCategory: CategoryArray;
  updateCompany: CompanyArray;
  updateFolder: FolderArray;
  updateImport: ImportArray;
  updatePlatform: PlatformArray;
  updateProduct: ProductArray;
  updateSelectField: SelectFieldArray;
  updateUser?: Maybe<UserArray>;
  updateUserGroup: UserGroupArray;
  validateFileImport?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateApprovalArgs = {
  data: DataApproval;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateArticleArgs = {
  data: DataArticle;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  data: DataCompany;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateFileArgs = {
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  parent?: InputMaybe<Scalars['ID']>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateFolderArgs = {
  data: DataFolder;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateImportArgs = {
  data: DataImport;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreatePlatformArgs = {
  data: DataPlatform;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['ID'];
};


export type MutationCreateReferenceArgs = {
  dataModel?: InputMaybe<DataModel>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateUserArgs = {
  data: DataUser;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationCreateUserGroupArgs = {
  data: DataUserGroup;
  model: Scalars['String'];
  user: Scalars['ID'];
};


export type MutationDeleteApprovalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteImportArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlatformArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationExportApprovalsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportArticlesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportCompaniesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportModelDataArgs = {
  exportType?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportPlatformsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationExportProductsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePdfArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationImportArticlesArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationImportCategoriesArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationImportModelDataArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationInlineMediaUploadArgs = {
  field: Scalars['String'];
  files?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  folder?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetApprovalArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetArticleArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCategoryArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetCompanyArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiResetProductArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateApprovalArgs = {
  data: DataApproval;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateArticleArgs = {
  data: DataArticle;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdateCompanyArgs = {
  data: DataCompany;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationMultiUpdatePlatformArgs = {
  data: DataPlatform;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model: Scalars['String'];
};


export type MutationMultiUpdateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResetApprovalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetArticleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationResetProfilePasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRunImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateApprovalArgs = {
  data: DataApproval;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateArticleArgs = {
  data?: InputMaybe<DataArticle>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCompanyArgs = {
  data: DataCompany;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateFolderArgs = {
  data: DataFolder;
  id?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateImportArgs = {
  data: DataImport;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  mappedFields?: InputMaybe<Array<InputMaybe<MappedFieldsData>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdatePlatformArgs = {
  data: DataPlatform;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateProductArgs = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  temp?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<Array<InputMaybe<DataOption>>>;
};


export type MutationUpdateUserArgs = {
  data: DataUser;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateUserGroupArgs = {
  data: DataUserGroup;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationValidateFileImportArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

export type Pagination = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Platform = {
  __typename?: 'Platform';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleTe?: Maybe<Scalars['String']>;
};

export type PlatformArray = {
  __typename?: 'PlatformArray';
  platform?: Maybe<PlatformData>;
};

export type PlatformData = {
  __typename?: 'PlatformData';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Maybe<Product>>>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Article>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  platforms?: Maybe<Array<Maybe<Platform>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type ProductArray = {
  __typename?: 'ProductArray';
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  files?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  product?: Maybe<ProductData>;
  technicalSpecifications?: Maybe<Array<Maybe<InlineTechnicalSpecification>>>;
};

export type ProductData = {
  __typename?: 'ProductData';
  accessories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companies?: Maybe<Array<Maybe<Scalars['ID']>>>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  publishDate?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  approval: ApprovalArray;
  approvalMultiEdit: Array<Maybe<ApprovalArray>>;
  article: ArticleArray;
  articleMultiEdit: Array<Maybe<ArticleArray>>;
  categoriesThree?: Maybe<Scalars['Json']>;
  category: CategoryArray;
  categoryMultiEdit: Array<Maybe<CategoryArray>>;
  company: CompanyArray;
  companyMultiEdit: Array<Maybe<CompanyArray>>;
  entities: Array<ConfHeader>;
  fileFields: Array<FileFields>;
  getDynamicTable?: Maybe<Scalars['Json']>;
  getFile?: Maybe<FileArray>;
  getFolder?: Maybe<FolderArray>;
  getMediacenter?: Maybe<Scalars['Json']>;
  getSelectField?: Maybe<SelectFieldArray>;
  getSelectFields?: Maybe<Array<Maybe<SelectField>>>;
  getSpecificSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getTechnicalSpecificationSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getUnitSelect?: Maybe<Array<Maybe<UnitOption>>>;
  import: ImportArray;
  inlineMedia?: Maybe<Array<InitInlineMedia>>;
  mappedFields: Array<MappedFields>;
  platform: PlatformArray;
  platformMultiEdit: Array<Maybe<PlatformArray>>;
  product: ProductArray;
  productMultiEdit: Array<Maybe<ProductArray>>;
  selectAccessories: Array<Product>;
  selectApprovals: Array<Approval>;
  selectCompanies: Array<Company>;
  selectCountries: Array<Country>;
  selectImport: Array<Import>;
  selectPlatforms: Array<Platform>;
  selectProduct: Array<Product>;
  selectStates: Array<State>;
  translateApproval: ApprovalArray;
  translateArticle: ArticleArray;
  translateCategory: CategoryArray;
  translateCompany: CompanyArray;
  translatePlatform: PlatformArray;
  translateProduct: ProductArray;
  user: UserArray;
  userGroup: UserGroupArray;
};


export type QueryApprovalArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryApprovalMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryArticleMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  mediacenter?: InputMaybe<Scalars['Boolean']>;
  model: Scalars['String'];
};


export type QueryCategoriesThreeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCategoryMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  mediacenter?: InputMaybe<Scalars['Boolean']>;
  model: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCompanyMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryFileFieldsArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryGetDynamicTableArgs = {
  filter?: InputMaybe<Scalars['Json']>;
  model: Scalars['String'];
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Sorting>;
};


export type QueryGetFileArgs = {
  id: Scalars['ID'];
};


export type QueryGetFolderArgs = {
  id: Scalars['ID'];
};


export type QueryGetMediacenterArgs = {
  dataModel?: InputMaybe<DataModel>;
  id?: InputMaybe<Scalars['ID']>;
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSpecificSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetTechnicalSpecificationSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetUnitSelectArgs = {
  model: Scalars['String'];
};


export type QueryImportArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryInlineMediaArgs = {
  fields?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMappedFieldsArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryPlatformArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryPlatformMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryProductMultiEditArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  model: Scalars['String'];
};


export type QuerySelectAccessoriesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySelectApprovalsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCompaniesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCountriesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectImportArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectPlatformsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectStatesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QueryTranslateApprovalArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateArticleArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCategoryArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCompanyArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslatePlatformArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateProductArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};

export type ReferenceArray = {
  __typename?: 'ReferenceArray';
  id?: Maybe<Scalars['ID']>;
  model?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ResetRespos = {
  __typename?: 'ResetRespos';
  error?: Maybe<Scalars['Json']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SelectField = {
  __typename?: 'SelectField';
  field: Scalars['String'];
  id: Scalars['ID'];
  model: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SelectFieldArray = {
  __typename?: 'SelectFieldArray';
  options?: Maybe<Array<Maybe<InlineOptions>>>;
  selectField?: Maybe<SelectField>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  titleDe?: Maybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Sorting = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type TechnicalOption = {
  __typename?: 'TechnicalOption';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnitOption = {
  __typename?: 'UnitOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  crUser?: Maybe<User>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserArray = {
  __typename?: 'UserArray';
  user?: Maybe<UserData>;
};

export type UserData = {
  __typename?: 'UserData';
  crUser?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  cr_user?: Maybe<User>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UserGroupArray = {
  __typename?: 'UserGroupArray';
  images?: Maybe<InlineMedia>;
  userGroup?: Maybe<UserGroupData>;
};

export type UserGroupData = {
  __typename?: 'UserGroupData';
  crUser?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UserModelQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
}>;


export type UserModelQuery = { __typename?: 'Query', user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', firstName: string, lastName: string, email: string, gender?: string | null, hidden?: boolean | null } | null } };

export type NewUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NewUserQuery = { __typename?: 'Query', user: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } };

export type UsersListQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  pagination: Types.Pagination;
  sorting?: Types.InputMaybe<Types.Sorting>;
}>;


export type UsersListQuery = { __typename?: 'Query', getDynamicTable?: any | null };

export type UpdateUserModelMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  id: Types.Scalars['ID'];
  data: Types.DataUser;
}>;


export type UpdateUserModelMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } | null };

export type CreateUserMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  data: Types.DataUser;
  user: Types.Scalars['ID'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'UserArray', user?: { __typename?: 'UserData', id: string } | null } | null };

export type DeleteUserModelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteUserModelMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', id: string } | null };


export const UserModelDocument = gql`
    query UserModel($id: ID!, $model: String!) {
  user(id: $id, model: $model) {
    user {
      firstName
      lastName
      email
      gender
      hidden
    }
  }
}
    `;

/**
 * __useUserModelQuery__
 *
 * To run a query within a React component, call `useUserModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUserModelQuery(baseOptions: Apollo.QueryHookOptions<UserModelQuery, UserModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserModelQuery, UserModelQueryVariables>(UserModelDocument, options);
      }
export function useUserModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserModelQuery, UserModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserModelQuery, UserModelQueryVariables>(UserModelDocument, options);
        }
export type UserModelQueryHookResult = ReturnType<typeof useUserModelQuery>;
export type UserModelLazyQueryHookResult = ReturnType<typeof useUserModelLazyQuery>;
export type UserModelQueryResult = Apollo.QueryResult<UserModelQuery, UserModelQueryVariables>;
export const NewUserDocument = gql`
    query newUser {
  user {
    user {
      id
    }
  }
}
    `;

/**
 * __useNewUserQuery__
 *
 * To run a query within a React component, call `useNewUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewUserQuery(baseOptions?: Apollo.QueryHookOptions<NewUserQuery, NewUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewUserQuery, NewUserQueryVariables>(NewUserDocument, options);
      }
export function useNewUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewUserQuery, NewUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewUserQuery, NewUserQueryVariables>(NewUserDocument, options);
        }
export type NewUserQueryHookResult = ReturnType<typeof useNewUserQuery>;
export type NewUserLazyQueryHookResult = ReturnType<typeof useNewUserLazyQuery>;
export type NewUserQueryResult = Apollo.QueryResult<NewUserQuery, NewUserQueryVariables>;
export const UsersListDocument = gql`
    query UsersList($model: String!, $search: String, $pagination: Pagination!, $sorting: Sorting) {
  getDynamicTable(
    model: $model
    search: $search
    pagination: $pagination
    sorting: $sorting
  )
}
    `;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      model: // value for 'model'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
      }
export function useUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
        }
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;
export const UpdateUserModelDocument = gql`
    mutation UpdateUserModel($model: String!, $id: ID!, $data: DataUser!) {
  updateUser(model: $model, id: $id, data: $data) {
    user {
      id
    }
  }
}
    `;
export type UpdateUserModelMutationFn = Apollo.MutationFunction<UpdateUserModelMutation, UpdateUserModelMutationVariables>;

/**
 * __useUpdateUserModelMutation__
 *
 * To run a mutation, you first call `useUpdateUserModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserModelMutation, { data, loading, error }] = useUpdateUserModelMutation({
 *   variables: {
 *      model: // value for 'model'
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserModelMutation, UpdateUserModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserModelMutation, UpdateUserModelMutationVariables>(UpdateUserModelDocument, options);
      }
export type UpdateUserModelMutationHookResult = ReturnType<typeof useUpdateUserModelMutation>;
export type UpdateUserModelMutationResult = Apollo.MutationResult<UpdateUserModelMutation>;
export type UpdateUserModelMutationOptions = Apollo.BaseMutationOptions<UpdateUserModelMutation, UpdateUserModelMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($model: String!, $data: DataUser!, $user: ID!) {
  createUser(model: $model, data: $data, user: $user) {
    user {
      id
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      model: // value for 'model'
 *      data: // value for 'data'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserModelDocument = gql`
    mutation DeleteUserModel($id: ID!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserModelMutationFn = Apollo.MutationFunction<DeleteUserModelMutation, DeleteUserModelMutationVariables>;

/**
 * __useDeleteUserModelMutation__
 *
 * To run a mutation, you first call `useDeleteUserModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserModelMutation, { data, loading, error }] = useDeleteUserModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserModelMutation, DeleteUserModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserModelMutation, DeleteUserModelMutationVariables>(DeleteUserModelDocument, options);
      }
export type DeleteUserModelMutationHookResult = ReturnType<typeof useDeleteUserModelMutation>;
export type DeleteUserModelMutationResult = Apollo.MutationResult<DeleteUserModelMutation>;
export type DeleteUserModelMutationOptions = Apollo.BaseMutationOptions<DeleteUserModelMutation, DeleteUserModelMutationVariables>;