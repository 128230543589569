import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const AdditionalProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const approvals: { id: string, title: string }[] = data.selectApprovals;
    const selectedApprovalsObj = state.approvals ? state.approvals.map((id: string) => approvals.filter((item) => item.id === id)[0]) : (values.approvals ? values.approvals.map((id: string) => approvals.filter((item) => item.id === id)[0]) : []);
    const [selectedApprovals, setSelectedApprovals] = useState<any[]>(selectedApprovalsObj);

    //------------------------------- Public methods -------------------------------------

    // Update multiselect on change with autocomplete
    const handleApprovalsChange = (value: any) => {
        setSelectedApprovals(value);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Approvals
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={approvals}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedApprovals || []}
                        defaultValue={selectedApprovals || []}
                        onChange={(event: any, value: any) => {
                            handleApprovalsChange(value);
                            onChange(event, 'approvals', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Approvals & Compliances" placeholder="Approvals & Compliances"
                                       name="approvals"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearApprovals"
                                    checked={state.clearApprovals ? state.clearApprovals : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Approvals & Compliances note"
                        name="approvalsNote"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.approvalsNote ? values.approvalsNote : ''}
                        onChange={onChange}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearApprovalsNote"
                                    checked={state.clearApprovalsNote ? state.clearApprovalsNote : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>


            </Grid>

        </React.Fragment>
    );
}


export default AdditionalProduct;
