import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    Divider as MuiDivider,
    Autocomplete, FormGroup, FormControlLabel, FormControl, InputLabel, MenuItem, Select
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;
    const companySolutions: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'companySolutions');
    const companyType: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'companyType');

    // States
    //const [selectedCompanyType, setSelectedCompanyType] = useState<any>(state.companyType ? companyType.filter((item) => item.id === state.companyType[0])  : (values.companyType ? companyType.filter((item) => item.id === values.companyType[0]) : []));
    const [selectedCompanyType, setSelectedCompanyType] = useState(state.companyType ? state.companyType : (values.companyType ? values.companyType[0] : ''));
    const [selectedCompanySolutions, setSelectedCompanySolutions] = useState<any>(state.companySolutions ? state.companySolutions.map((id: string) => companySolutions.filter((item) => item.id === id)[0]) : (values.companySolutions ? values.companySolutions.map((id: string) => companySolutions.filter((item) => item.id === id)[0]) : []));

    //------------------------------- Public methods -------------------------------------


    //SINGLE SELECT autocomplete not working change tab
    // // Update companySolutions on change
    // const handleCompanyTypeChange = (value: any) => {
    //     setSelectedCompanyType(value ? value : []);
    // };

    // Update category on change
    const handleCompanySolutionChange = (value: any) => {
        setSelectedCompanySolutions(value)

    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={10}>
                    <TextField
                        name="title"
                        label={multiEdit ? 'Title' : 'Title *'}
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"
                    />

                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={4} mb={5}>
                    {/*<Autocomplete*/}
                    {/*    options={companyType}*/}
                    {/*    disableCloseOnSelect={false}*/}
                    {/*    getOptionLabel={(option) => option.title ? option.title : ''}*/}
                    {/*    value={selectedCompanyType[0] ? selectedCompanyType[0] : selectedCompanyType}*/}
                    {/*    onChange={(event: any, value: any) => {*/}
                    {/*        onChange(event, 'companyType', 'autocomplete', value)*/}
                    {/*        handleCompanyTypeChange(value);*/}
                    {/*    }}*/}
                    {/*    renderInput={(params) => (*/}
                    {/*        <TextField {...params} label="Company type" placeholder="Company type" name="companyType"/>*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <FormControl className="full-width">
                        <InputLabel>Company type</InputLabel>
                        <Select
                            name="companyType"
                            value={selectedCompanyType}
                            label="Company type"
                            onChange={(event: any) => {
                                setSelectedCompanyType(event.target.value);
                                onChange(event)
                            }}
                        >
                            {companyType.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCompanyType"
                                    checked={state.clearCompanyType ? state.clearCompanyType : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} md={4} mb={5}>
                    <Autocomplete
                        multiple
                        options={companySolutions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        defaultValue={selectedCompanySolutions || []}
                        value={selectedCompanySolutions || []}
                        onChange={(event: any, value: any) => {
                            handleCompanySolutionChange(value);
                            onChange(event, 'companySolutions', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Company solutions" placeholder="Company solutions"
                                       name="companySolutions"/>
                        )}
                    />
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearCompanySolutions"
                                    checked={state.clearCompanySolutions ? state.clearCompanySolutions : false}
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoCompany;
