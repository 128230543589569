import React, {useEffect, useState} from "react";

import {
    Accordion, AccordionDetails, AccordionSummary, Autocomplete,
    Box, Button,
    Checkbox, Divider as MuiDivider, Grid,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel, TextField,
    Toolbar,
    Typography,

} from "@mui/material";
import {
    FilterList as FilterListIcon
} from "@mui/icons-material";
import {spacing} from "@mui/system";
import {Edit3 as EditIcon, Trash2 as TrashIcon} from "react-feather";

// Types
import {EnhancedTableHeadProps} from "../../types/enhancedTableHeadProps";
import {EnhancedTableToolbarProps} from "../../types/enhancedTableToolbarProps";
import {TableData} from "../../types/tableData";
import {Filters} from "../../types/filters";

// Styling
import styled from "styled-components/macro";
import {SelectChangeEvent} from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import {HeadCell} from "../../types/headCell";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const Paper = styled(MuiPaper)(spacing);

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        rowData,
        onRequestSort,
        rowsPerPage,
        rowsCount,
        model
    } = props;

    //------------------------------- Public methods -------------------------------------


    const createSortHandler = (property: string) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {(model != 'SelectField' && model !='User' && model !='Import') &&
                    <TableCell padding="checkbox" className="first-header-cell">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowsPerPage > 0 && numSelected === rowsCount}
                            onChange={onSelectAllClick}
                            inputProps={{"aria-label": "select all"}}
                        />
                    </TableCell>
                }
                {rowData.map((headCell:HeadCell) => (
                    headCell.fieldType != 'image'
                            ? <TableCell
                                key={headCell.fieldName}
                                align='left'
                                padding="normal"
                                sortDirection={orderBy === headCell.fieldName ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.fieldName}
                                    direction={orderBy === headCell.fieldName ? order : "asc"}
                                    onClick={createSortHandler(headCell.fieldName)}
                                >
                                    {headCell.fieldLabel}
                                </TableSortLabel>
                            </TableCell>
                            : <TableCell
                                key={headCell.fieldName}
                                align='left'
                                padding="normal"
                            >
                                {headCell.fieldLabel}
                            </TableCell>
                ))}
                <TableCell
                    align='right'
                    padding="normal"
                    className="last-th"
                >
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const {numSelected, filters, onFilter, filter} = props;
    const [state, setState] = useState({});
    const [selecteCurrentFilter, setSelecteCurrentFilter] = useState<any>( {});
    const [accordionOpen, setAccordionOpen] = useState(false)

    useEffect(() => {
        setSelecteCurrentFilter(filter);
        if(filter && Object.keys(filter).length != 0){
            setAccordionOpen(true);
        }
    }, [filter]);
    //------------------------------- Public methods -------------------------------------


    // This function is called when the select changes
    const inputHandler = (event: SelectChangeEvent<HTMLSelectElement>, value: any,  currentFilter: Filters) => {
        const fieldName = currentFilter.fieldName;
        setSelecteCurrentFilter({...selecteCurrentFilter, [fieldName]: value});

        // Update select value
        setState({...state, [fieldName]: value});

    };

    const submitFilter = () => {
        if(onFilter){
            onFilter(selecteCurrentFilter);
        }
    }
    const accordionChange = () => {
        setAccordionOpen(!accordionOpen);
    }


    return (
        <React.Fragment>
            {filters && filters.length > 0 &&
                <Toolbar className="toolbar-wrapper">
                    <Grid item xs={12}>
                        <Accordion className="filters-wrapper" expanded={accordionOpen} onChange={accordionChange}>
                            <AccordionSummary
                                expandIcon={<FilterListIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="h6" id="tableTitle">
                                    Filters
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={6}>
                                    {filters.map((filter: Filters) => {
                                        return (
                                            <Grid item xs={12} md={4} lg={3} key={filter.fieldName} className="filter-item">
                                                <Autocomplete
                                                    multiple
                                                    options={filter.options}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option.label}
                                                    // @ts-ignore
                                                    value={selecteCurrentFilter[filter.fieldName] || []}
                                                    // @ts-ignore
                                                    defaultValue={selecteCurrentFilter[filter.fieldName] || []}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    onChange={(e: any, value: any) => inputHandler(e, value, filter)}
                                                    renderOption={(props, option, {selected}) => (
                                                        <li {...props} key={option.value}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{marginRight: 8}}
                                                                checked={selected}
                                                            />
                                                            {option.label}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label={filter.fieldLabel} placeholder={filter.fieldLabel}
                                                                   name={filter.fieldName}/>
                                                    )}
                                                />
                                            </Grid>
                                        );
                                    })}
                                    <Grid item xs={12} md={2} lg={2}>
                                        <Button variant="contained" color="primary" onClick={submitFilter} className="filter-btn">
                                            Filter
                                        </Button>
                                    </Grid>
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Toolbar>
            }
        </React.Fragment>
    );
};

const ListViewTable: React.FC<TableData> = (props) => {
    const {rows, tableHeaders, onSelectHandler, onDeleteHandler, onPaginateHandler, onSortHandler, onFilterHandler, count, filters, filter, sorting, onMultiEditHandler, selectedArray, model} = props;
    const [order, setOrder] = useState<any>(sorting ? sorting.order : "desc");
    const [orderBy, setOrderBy] = useState(sorting ? sorting.field : "updated_at");
    const [selected, setSelected] = useState<Array<string>>(selectedArray ? selectedArray : []);
    const [page, setPage] = useState(props.page);
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);

    useEffect(() => {
        setSelected(selectedArray ? selectedArray : []);
    }, [selectedArray]);

    useEffect(() => {
        setOrderBy(sorting ? sorting.field : "updated_at");
        setOrder(sorting && sorting.order=="asc" ? "desc" : "asc");
    }, [sorting]);

    //------------------------------- Public methods -------------------------------------

    const handleRequestSort = (event: any, property: string) => {
        const isAsc = orderBy === property && order === "asc";

        // @ts-ignore
        onSortHandler(property, order);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('headers',tableHeaders);
        console.log('rows',rows);
        if (event.target.checked) {
            const newSelecteds: Array<string> = rows.map((n: any) => n.id);
            setSelected(newSelecteds);

            if(onMultiEditHandler) {
                onMultiEditHandler(newSelecteds);
            }

            return;
        }
        setSelected([]);
        if(onMultiEditHandler) {
            onMultiEditHandler([]);
        }


    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: Array<string> = [];

        if (selectedArray?.length == 0) {
            setSelected([]);
        }

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
        if(onMultiEditHandler){
            onMultiEditHandler(newSelected);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPaginateHandler(rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        onPaginateHandler(parseInt(event.target.value, 10), page);
    };

    const isSelected = (id: string) => selected.indexOf(id) !== -1;

    return (
        <div>
            <Paper>
                <EnhancedTableToolbar numSelected={selected.length} filters={filters} onFilter={onFilterHandler} filter={filter}/>
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                        className="list-table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={tableHeaders.length}
                            rowData={tableHeaders}
                            rowsPerPage={rowsPerPage}
                            rowsCount={rows.length}
                            model={model}
                        />
                        <TableBody>
                            {rows.map((row: any, index: number) => {
                                    let isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={`${row.id}-${index}`}
                                            selected={isItemSelected}
                                        >
                                            {(model != 'SelectField' && model !='User' && model !='Import') &&
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{"aria-labelledby": labelId}}
                                                        onClick={(event) => handleClick(event, row.id)}
                                                    />
                                                </TableCell>
                                            }
                                            {Object.keys(row).map((item: string, index) => {
                                                switch (item) {
                                                    case 'id':
                                                        break;
                                                    case 'image':
                                                    case 'logo':
                                                        return <TableCell
                                                            className="small-cell click-row"
                                                            key={`${row.id}-${index}`}
                                                            align="left"
                                                            onClick={() => onSelectHandler(row.id)}
                                                            >
                                                            {row[item] !== '' && <Avatar src={row[item]}/>}
                                                        </TableCell>
                                                    default:
                                                        return <TableCell
                                                            className="click-row"
                                                            key={`${row.id}-${index}`}
                                                            align="left"
                                                            dangerouslySetInnerHTML={{__html: row[item]}}
                                                            onClick={() => onSelectHandler(row.id)}/>
                                                }
                                            })}

                                            <TableCell padding="none" align="right"  className="last-td">
                                                <Box mr={2}>
                                                    <IconButton aria-label="details" size="medium"
                                                                onClick={() => onSelectHandler(row.id)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <span className="spacer"></span>
                                                    {onDeleteHandler &&
                                                        <IconButton aria-label="delete" size="medium" className="btn-delete"
                                                                    onClick={() => onDeleteHandler(row.id)}>
                                                            <TrashIcon/>
                                                        </IconButton>
                                                    }
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default ListViewTable;
