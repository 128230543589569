import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, MenuItem, Menu
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CategoriesContext} from "./store/categories-context";


// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Edit, Add as AddIcon, FileDownload, FileUpload, PictureAsPdf, MoreHoriz} from "@mui/icons-material";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchBar from "../components/SearchBar";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Categories() {
    const categoriesCtx = useContext(CategoriesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = categoriesCtx.headers;
    const model = 'categories';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Categories"/>

            <Grid item>
                <Typography variant="h1" gutterBottom display="inline">
                    Categories
                </Typography>
            </Grid>

            <Grid justifyContent="space-between" container spacing={10} mb={10}>

                <Grid item>
                    <SearchBar text={categoriesCtx.search} onSearch={categoriesCtx.onSearch}/>
                </Grid>

                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={categoriesCtx.addItem} startIcon={<AddIcon/>}>
                            New Category
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (categoriesCtx.exportCsv) {
                                    categoriesCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV Export Categories
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (categoriesCtx.exportCsv) {
                                    categoriesCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Categories
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS Import Categories
                            </MenuItem>

                            {categoriesCtx.selected && categoriesCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={categoriesCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Multi Edit
                                    </MenuItem>
                                    <MenuItem onClick={(event: any) => {
                                        if(categoriesCtx.generatePdf){
                                            categoriesCtx.generatePdf()
                                        }
                                        handleClose()
                                    }} disableRipple>
                                        <PictureAsPdf/>
                                        Generate Pdf
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (categoriesCtx.importCsv) {
                                    categoriesCtx.importCsv(event.target.files)
                                }
                            }}
                        />

                        {/*{categoriesCtx.selected && categoriesCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={categoriesCtx.generatePdf}*/}
                        {/*            startIcon={<PictureAsPdf/>} className="margin-right-btn">*/}
                        {/*        Generate Pdf*/}
                        {/*    </Button>*/}
                        {/*}*/}

                        {/*<Button variant="contained" color="primary" onClick={*/}
                        {/*    (event: any) => {*/}
                        {/*        if (categoriesCtx.exportCsv) {*/}
                        {/*            categoriesCtx.exportCsv('csv')*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    CSV Export Categories*/}
                        {/*</Button>*/}
                        {/*<Button variant="contained" color="primary" onClick={*/}
                        {/*    (event: any) => {*/}
                        {/*        if (categoriesCtx.exportCsv) {*/}
                        {/*            categoriesCtx.exportCsv('xls')*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    XLS Export Categories*/}
                        {/*</Button>*/}
                        {/*<Input*/}
                        {/*    ref={fileInput}*/}
                        {/*    accept=".csv,.xlsx,.xls"*/}
                        {/*    type="file"*/}
                        {/*    name="file"*/}
                        {/*    onChange={(event: any) => {*/}
                        {/*        if (categoriesCtx.importCsv) {*/}
                        {/*            categoriesCtx.importCsv(event.target.files)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button variant="contained" color="primary" onClick={(e: any) => {*/}
                        {/*    if (fileInput.current) {*/}
                        {/*        fileInput.current.click();*/}
                        {/*    }*/}
                        {/*}} startIcon={<FileUpload/>} className="margin-right-btn">*/}
                        {/*    CSV/XLS Import Categories*/}
                        {/*</Button>*/}

                        {/*{categoriesCtx.selected && categoriesCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={categoriesCtx.multiEdit}*/}
                        {/*            className="margin-right-btn" startIcon={<Edit/>}>*/}
                        {/*        Multi edit*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button variant="contained" color="primary" onClick={categoriesCtx.addItem}*/}
                        {/*        startIcon={<AddIcon/>}>*/}
                        {/*    New Category*/}
                        {/*</Button>*/}
                    </div>
                </Grid>
            </Grid>


            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={categoriesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={categoriesCtx.removeItem}
                    onSelectHandler={categoriesCtx.selectItem}
                    onPaginateHandler={categoriesCtx.onPaginate}
                    onSortHandler={categoriesCtx.onSort}
                    count={categoriesCtx.count}
                    page={categoriesCtx.page}
                    rowsPerPage={categoriesCtx.rowsPerPage}
                    sorting={categoriesCtx.sorting}
                    filters={categoriesCtx.filters}
                    onMultiEditHandler={categoriesCtx.multiSelected}
                    selectedArray={categoriesCtx.selected}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Categories;
