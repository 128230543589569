import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import TabPanel from "../../components/TabPanel";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    UserModelQuery,
    NewUserQuery,
    useUserModelQuery,
    useCreateUserMutation,
    useNewUserQuery,
    useUpdateUserModelMutation,
    CreateUserMutation
} from "../../../graphql/settings/users/users.graphql-gen";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import GeneralInfoUser from "./fieldGroups/GeneralInfoUser";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
});

const User: React.FC = () => {
    let title: string = 'New user'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const model: string = 'User';

    // Queries
    const {data: data, error: error, loading: loading} = useUserModelQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: UserModelQuery) => {
            if (data.user && data.user.user) {
                setState(data.user.user);
                setInicialValue({
                    "data": data.user.user
                });
            }
            //setUserId(data.user.user?.id);
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewUserQuery({

        skip: id !== '',
        onCompleted: (data: NewUserQuery) => {
            setState({});
            setInicialValue({});
            setUserId(data.user.user?.id);
        }
    });
    const [updateUser] = useUpdateUserModelMutation();
    const [createUser] = useCreateUserMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [userId, setUserId] = useState<any>();

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.user && dataObj.user.user) {
        title = dataObj.user.user.title;
    }

    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveUser = () => {
        if (id !== '') {
            updateUserMutation(state);
        } else {
            createUserMutation(state);
        }
        setInicialValue({
            "data": state,
        });
    }

    // Update user mutation
    const updateUserMutation = (data: any) => {
        updateUser({
            variables: {
                model,
                id,
                data
            }
        }).then(r => {
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create user mutation
    const createUserMutation = (data: any) => {
        createUser({
            variables: {
                model,
                data,
                user: userId
            },
        })
            .then((res: FetchResult<CreateUserMutation>) => {
                if (res.data && res.data.createUser &&  res.data.createUser.user) {
                    setUpdated(true);
                    navigate(`/settings/users/${res.data.createUser.user.id}`, {replace: true})
                }
            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        navigate(`/settings/users`, {replace: true})
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state});
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }


    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});
    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="User"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveUser}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoUser
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>


                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default User;
