import React from "react";

import {
    Grid,
    TextField as MuiTextField, Typography,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const SeoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Title De"
                        name="seoTitleDe"
                        value={values.seoTitleDe ? values.seoTitleDe : ''}
                        error={Boolean(touched.seoTitleDe && errors.seoTitleDe)}
                        fullWidth
                        helperText={touched.seoTitleDe && errors.seoTitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Title"
                        name="seoTitle"
                        value={values.seoTitle ? values.seoTitle : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Description De"
                        name="seoDescriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescriptionDe ? values.seoDescriptionDe : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Description"
                        name="seoDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescription ? values.seoDescription : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Keywords De"
                        name="seoKeywordsDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywordsDe ? values.seoKeywordsDe : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Keywords"
                        name="seoKeywords"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywords ? values.seoKeywords : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default SeoCategory;
