import * as React from "react";
import styled from "styled-components/macro";
import {User} from "react-feather";
import {useNavigate} from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import {useSignOutMutation} from "../../graphql/auth/auth.graphql-gen";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const [logoutUser] = useSignOutMutation();


  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const navigateProfile = () => {
    navigate("/profile");
  }

  const navigateReset = () => {
    navigate("/auth/reset-profile-password");
  }

  const handleSignOut = async () => {
    logoutUser();
    localStorage.clear();
    navigate("/auth/login");
  };


  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          sx={{ color: "#fff", backgroundColor: "#FF4C00", marginLeft: '15px', '&:hover': {
                  color: '#FF4C00',
              } }}
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={navigateProfile}>Profile</MenuItem>
        <MenuItem onClick={navigateReset}>Reset password</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>

      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
