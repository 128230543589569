import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {Formik} from "formik";

import {
    Alert as MuiAlert,
    Button,
    TextField as MuiTextField,
} from "@mui/material";
import {spacing} from "@mui/system";


import {useResetPasswordMutation} from "../../graphql/auth/auth.graphql-gen";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function ResetPassword() {
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamsReset = queryParams.get('password_reset');
    const [resetPassword] = useResetPasswordMutation();

    const token: string = queryParamsReset ? queryParamsReset : '';


    return (
        <Formik
            initialValues={{
                password: "",
                confirmPassword: "",
                submit: false,
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password length should be at least 8 characters'),
                confirmPassword: Yup.string()
                    .required('Confirm Password is required')
                    .oneOf([Yup.ref('password')], 'Passwords must and should match')

            })}
            onSubmit={async (values, {setErrors, setStatus, setSubmitting}) => {
                try {
                    resetPassword({
                        variables: {password: values.password, token: token}
                    });
                    navigate("/auth/login");
                } catch (error: any) {
                    const message = error.message || "Something went wrong";

                    setStatus({success: false});
                    setErrors({submit: message});
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {errors.submit && (
                        <Alert mt={2} mb={1} severity="warning">
                            {errors.submit}
                        </Alert>
                    )}

                    <TextField
                        type="password"
                        name="password"
                        label="Password"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <TextField
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                        value={values.confirmPassword}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        my={3}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                    >
                        Save password
                    </Button>
                </form>
            )}
        </Formik>
    );
}

export default ResetPassword;
