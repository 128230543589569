import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoMediacenter: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>

                        <TextField
                            name="title"
                            label = 'Folder name'
                            value={values.title ? values.title : ''}
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            onChange={onChange}
                            variant="outlined"
                        />

                </Grid>

            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoMediacenter;
