import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
} from "@mui/material";

import ReactQuill from "react-quill";

// Styles
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;

    // States
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));

    //------------------------------- Public methods -------------------------------------


    // Update description on change
    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title"
                        value={values.title ? values.title : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitleDe"
                        label="Subtitle De"
                        value={values.subtitleDe ? values.subtitleDe : ''}
                        error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                        fullWidth
                        helperText={touched.subtitleDe && errors.subtitleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitle"
                        label="Subtitle"
                        value={values.subtitle ? values.subtitle : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Short description De"
                        name="shortDescriptionDe"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescriptionDe ? values.shortDescriptionDe : ''}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Short description"
                        name="shortDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescription ? values.shortDescription : ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Description De</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={descriptionDe}
                            onChange={(e) => {
                                handleChangeDescriptionDe(e);
                                onChange(e, 'descriptionDe')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <QuillWrapper className="full-width read-only">
                        <InputLabel className="mb-5">Description</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={values.description}
                            readOnly={true}
                        />
                    </QuillWrapper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default GeneralInfoProduct;
