import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider, FormGroup, FormControlLabel, Checkbox,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const PromotionProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state, multiEdit} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                SEO
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="seoTitle"
                        label="Seo Title"
                        value={values.seoTitle ? values.seoTitle : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoTitle"
                                    checked={state.clearSeoTitle ? state.clearSeoTitle : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Seo Description"
                        name="seoDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescription ? values.seoDescription : ''}
                        onChange={onChange}/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoDescription"
                                    checked={state.clearSeoDescription ? state.clearSeoDescription : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Seo Keywords"
                        name="seoKeywords"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywords ? values.seoKeywords : ''}
                        onChange={onChange}/>
                    {multiEdit &&
                        <FormGroup className="clear-check">
                            <FormControlLabel control={
                                <Checkbox
                                    name="clearSeoKeywords"
                                    checked={state.clearSeoKeywords ? state.clearSeoKeywords : false}
                                    onChange={(e) =>{onChange(e)}}
                                />
                            } label="Clear"/>
                        </FormGroup>
                    }
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default PromotionProduct;
